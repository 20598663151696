import communicationNoticeService from '../../../../services/provider/communicationNotices'
import VueHtml2pdf from 'vue-html2pdf'
import webform from '../confirmation/pdf-webform'
import { COMMUNICATION_TYPE } from '../../../../misc/commons'
export default {
    props: {
        organizationId: {type: Number, default: null},
        communicationId: {type: String, default: null},
        providerCommunicationId: {type: Number, default: null},
        communicationType: { type: String, default: COMMUNICATION_TYPE.RECALL },
        communicationAffectedCustomerId: {type: Number, default: null},
        email: {type: String, default: null},
    },
    components: {
        VueHtml2pdf,
        webform
    },
    data() {
        return {
            item: {},
            responder: {},
            internalActions: null,
            histories: [],
            cnt: 1,
            typeOfCommunication: 'recall',
            filename: null,
            headerName: null,
            htmltopdfoptions: {
                margin: 1,
                filename: this.filename
            }
        }
    },
    created() {
        this.communicationNotice()
        this.communication()
        this.communicationHistories()
    },
    methods: {
        communicationNotice(){
            communicationNoticeService.communicationNotice(this.providerCommunicationId, this.communicationType, this.email).then(resp => {
                if (!resp.error) {
                    this.responder=resp.data.d
                }
            })
        },
        communication() {
            if(this.communicationType == COMMUNICATION_TYPE.PRODUCTDISRUPTION)
            {
                communicationNoticeService.communicationForPd(this.organizationId, this.communicationId, this.providerCommunicationId, this.communicationAffectedCustomerId).then(resp => {
                    if (!resp.error) {
                        this.item = resp.data.d
                        this.headerName=resp.data.d.communicationName + ' - '+ resp.data.d?.recallsitename
                        this.filename=resp.data.d.communicationName + ' - '+ resp.data.d?.recallsitename +'_'+resp.data.d.communicationUID +'.pdf'
                        this.internalActions = resp.data.d.actions
                        for (let i = 0; i < this.internalActions.length; i++) {
                            let List = []
                            if (this.internalActions[i].action_type == 'MultipleChoice') {
                                for (let j = 0; j < this.internalActions[i].actionValues.length; j++) {
                                    List.push({ value: this.cnt, text: this.internalActions[i].actionValues[j].value, })
                                    if (!this.internalActions[i].isAllowOnlyOne) {
                                        this.internalActions[i].response_data.valueMultiChoice.forEach((element) => {
                                            if (element.valueMultiChoice == this.internalActions[i].actionValues[j].value) {
                                                this.internalActions[i].multiSelectedValues.push(this.cnt)
                                            }
                                        })
                                    } else {
                                        if (this.internalActions[i].response_data.valueMultiChoice.length > 0) {
                                            if (this.internalActions[i].response_data.valueMultiChoice[0].valueMultiChoice == this.internalActions[i].actionValues[j].value) {
                                                this.internalActions[i].singleSelectedValues = this.cnt
                                            }
                                        }
                                    }
                                    this.cnt++
                                }
                                this.internalActions[i].actionValues = []
                                this.internalActions[i].actionValues = List
                            }
                        }
                        this.internalActions = this.internalActions.filter(x=>x.isCustomActionType==false)
                    }
                })
            }
            else{
                communicationNoticeService.communication(this.organizationId, this.communicationId, this.providerCommunicationId).then(resp => {
                    if (!resp.error) {
                        this.item = resp.data.d
                        this.headerName=resp.data.d.communicationName + ' - '+ resp.data.d?.recallsitename
                        this.filename=resp.data.d.communicationName + ' - '+ resp.data.d?.recallsitename +'_'+resp.data.d.communicationUID +'.pdf'
                        this.internalActions = resp.data.d.actions
                        for (let i = 0; i < this.internalActions.length; i++) {
                            let List = []
                            if (this.internalActions[i].action_type == 'MultipleChoice') {
                                for (let j = 0; j < this.internalActions[i].actionValues.length; j++) {
                                    List.push({ value: this.cnt, text: this.internalActions[i].actionValues[j].value, })
                                    if (!this.internalActions[i].isAllowOnlyOne) {
                                        this.internalActions[i].response_data.valueMultiChoice.forEach((element) => {
                                            if (element.valueMultiChoice == this.internalActions[i].actionValues[j].value) {
                                                this.internalActions[i].multiSelectedValues.push(this.cnt)
                                            }
                                        })
                                    } else {
                                        if (this.internalActions[i].response_data.valueMultiChoice.length > 0) {
                                            if (this.internalActions[i].response_data.valueMultiChoice[0].valueMultiChoice == this.internalActions[i].actionValues[j].value) {
                                                this.internalActions[i].singleSelectedValues = this.cnt
                                            }
                                        }
                                    }
                                    this.cnt++
                                }
                                this.internalActions[i].actionValues = []
                                this.internalActions[i].actionValues = List
                            }
                        }
                        this.internalActions = this.internalActions.filter(x=>x.isCustomActionType==false)
                    }
                })
            }
        },
        communicationHistories() {
            communicationNoticeService.communicationHistories(this.organizationId, this.communicationId, this.providerCommunicationId).then(resp => {
                if (!resp.error) {
                    this.histories = resp.data.d
                }
            })
        },
        generateReport () {
            this._loadingStart()
            this.$refs.html2Pdf.generatePdf()
            setTimeout(() => {
                this._loadingEnd()
            }, 5000)

        },
        createNewAccount(){
            this.openExternalLink('https://www.notisphere.com/forms/providers/sign-up-to-notisphere-providers')
        },
        findOutMore(){
            this.openExternalLink( 'https://www.notisphere.com/forms/providers/request-notisphere-information-providers')
        },
    }
}